<template>
  <div id="servisWrapper">
    <div id="tableWrapper">
      <table id="isporuka">
        <thead>
          <tr>
            <th>RED VOŽNJE</th>
            <th>PON</th>
            <th>UTO</th>
            <th>SRI</th>
            <th>ČET</th>
            <th>PET</th>
            <th>SUB</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Andrijevica</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td><img src="/img/avto.png" alt="" /></td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
          </tr>
          <tr>
            <td>Bar</td>
            <td><img src="/img/avto.png" alt="" /></td>
            <td>&nbsp;</td>
            <td><img src="/img/avto.png" alt="" /></td>
            <td>&nbsp;</td>
            <td><img src="/img/avto.png" alt="" /></td>
            <td>&nbsp;</td>
          </tr>
          <tr>
            <td>Berane</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td><img src="/img/avto.png" alt="" /></td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
          </tr>
          <tr>
            <td>Bijelo Polje</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td><img src="/img/avto.png" alt="" /></td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
          </tr>
          <tr>
            <td>Budva</td>
            <td>&nbsp;</td>
            <td><img src="/img/avto.png" /></td>
            <td>&nbsp;</td>
            <td><img src="/img/avto.png" /></td>
            <td>&nbsp;</td>
            <td><img src="/img/avto.png" /></td>
          </tr>
          <tr>
            <td>Cetinje</td>
            <td>&nbsp;</td>
            <td><img src="/img/avto.png" /></td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
          </tr>
          <tr>
            <td>Danilovgrad</td>
            <td>&nbsp;</td>
            <td><img src="/img/avto.png" /></td>
            <td>&nbsp;</td>
            <td><img src="/img/avto.png" /></td>
            <td>&nbsp;</td>
            <td><img src="/img/avto.png" /></td>
          </tr>
          <tr>
            <td>Herceg Novi</td>
            <td><img src="/img/avto.png" /></td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td><img src="/img/avto.png" /></td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
          </tr>
          <tr>
            <td>Kolašin</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td><img src="/img/avto.png" alt="" /></td>
            <td>&nbsp;</td>
          </tr>
          <tr>
            <td>Kotor</td>
            <td>&nbsp;</td>
            <td><img src="/img/avto.png" /></td>
            <td>&nbsp;</td>
            <td><img src="/img/avto.png" /></td>
            <td>&nbsp;</td>
            <td><img src="/img/avto.png" /></td>
          </tr>
          <tr>
            <td>Mojkovac</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td><img src="/img/avto.png" alt="" /></td>
            <td>&nbsp;</td>
          </tr>
          <tr>
            <td>Nikšić</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td><img src="/img/avto.png" /></td>
          </tr>
          <tr>
            <td>Petrovac, Buljarica i Rijeka Reževića</td>
            <td></td>
            <td>&nbsp;</td>
            <td><img src="/img/avto.png" alt="" /></td>
            <td>&nbsp;</td>
            <td></td>
            <td>&nbsp;</td>
          </tr>
          <tr>
            <td>Plav</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td><img src="/img/avto.png" alt="" /></td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
          </tr>
          <tr>
            <td>Pljevlja</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td><img src="/img/avto.png" alt="" /></td>
            <td>&nbsp;</td>
          </tr>
          <tr>
            <td>Podgorica</td>
            <td><img src="/img/avto.png" /></td>
            <td><img src="/img/avto.png" /></td>
            <td><img src="/img/avto.png" alt="" /></td>
            <td><img src="/img/avto.png" /></td>
            <td><img src="/img/avto.png" alt="" /></td>
            <td><img src="/img/avto.png" /></td>
          </tr>
          <tr>
            <td>Bioče</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td><img src="/img/avto.png" alt="" /></td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
          </tr>
          <tr>
            <td>Tivat</td>
            <td>&nbsp;</td>
            <td><img src="/img/avto.png" alt="" /></td>
            <td>&nbsp;</td>
            <td><img src="/img/avto.png" alt="" /></td>
            <td>&nbsp;</td>
            <td><img src="/img/avto.png" alt="" /></td>
          </tr>
          <tr>
            <td>Tuzi</td>
            <td><img src="/img/avto.png" /></td>
            <td>&nbsp;</td>
            <td><img src="/img/avto.png" alt="" /></td>
            <td>&nbsp;</td>
            <td><img src="/img/avto.png" alt="" /></td>
            <td>&nbsp;</td>
          </tr>
          <tr>
            <td>Ulcinj</td>
            <td><img src="/img/avto.png" /></td>
            <td>&nbsp;</td>
            <td></td>
            <td>&nbsp;</td>
            <td><img src="/img/avto.png" alt="" /></td>
            <td>&nbsp;</td>
          </tr>
          <tr>
            <td>Žabljak</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td><img src="/img/avto.png" alt="" /></td>
            <td>&nbsp;</td>
          </tr>
          <tr>
            <td>Zeta</td>
            <td><img src="/img/avto.png" /></td>
            <td>&nbsp;</td>
            <td><img src="/img/avto.png" alt="" /></td>
            <td>&nbsp;</td>
            <td><img src="/img/avto.png" alt="" /></td>
            <td>&nbsp;</td>
          </tr>
        </tbody>
      </table>
      <br />
      
        Mateševo, Veruša, Kuči i Virpazar - svaki drugi petak u mjesecu<br />
		Grahovo, Vilusi, Vraćenovići i Studeno - svaka druga subota u mjesecu<br />
		Crmnica, Virpazar, Ostros - svaki drugi ponedjeljak u mjesecu<br />
		Ivanova korita, Njeguši, Rijeka Crnojevića - svaki drugi utorak u mjesecu<br />
		Rožaje, Andrijevica, Plav, Gusinje i Petnjica - svaka druga srijeda<br />
		Šavnik, Boan - jednom mjesečno<br />
		Boljanice, Potkovac, Metaljka, Šula - jednom mjesečno<br />
      
      <!--
      Veruši, Kuči i Virpazar - svaki drugi petak u mjesecu<br />
      Grahovo, Vilusi, Vraćenovići i Studeno - svaka druga subota u mjesecu<br />
      Crmnica, Virpazar, Ostros - svaki drugi ponedjeljak u mjesecu<br />
      Ivanova korita - svaki drugi utorak u mjesecu<br />
      -->
    </div>

    <div id="pravilnikWrapper">
      <h2>Pravilnik kompanije</h2>
      <p>
      
      1. Prodavac preuzima obavezu besplatnog prevoza (do mjesta dostupnog teretnim vozilima) i montaže robe (ne uključujući nedelju, državne i vjerske
      praznike), koja se vrši najkasnije u roku – do 5 dana, od momenta prijema robe.<br /><br />
2.	Prodavac NE VRŠI UNOŠENJE ROBE u stan kupca I NE KAČI ELEMENTE NA ZID. Za neuklapanje artikala u stambeni prostor potrošača, 
      prodavac ne snosi odgovornost.<br /><br />
3.	Isporučena, otpakovana ili namontirana roba, kao i roba prodata (proknjižena) u prethodnom mjesecu ne može se zamijeniti.<br /><br />
4.	Prodavac se obavezuje da prema uslovima iz ovog ugovora poruči i proda robu kupcu, u svemu po specifikaciji koja je sastavni dio ugovora.<br /><br />
5.	Za poručenu robu kupac se obavezuje da uplati avans, minimum 50% od ukupne vrijednosti naručene robe, nakon čega počinju teći rokovi iz člana
      6. Preostalih 50% plaća se po prispjeću robe u centralni magacin.<br /><br />
6.	Rok za prispjeće robe u centralni magacin prodavca naglašen je na računu, s tim, što se u slučaju nepredviđenih okolnosti: smetnji u prevozu,
         vremenskih nepogoda, zadržavanju na carinskom terminalu, ovaj rok može produžiti za još 7 dana.<br /><br />
7.	U slučaju prekoračenja rokova isporuke, iz člana 6. ugovora, kupac zadržava pravo na povraćaj avansa sa zakonskom zateznom kamatom, ili
      dodatnim popustom 10%, ili se nudi zamjena za drugi artikal, uz odgovarajući popust od strane "DAJKOVIĆ CO NAMOS".<br /><br />
8.	Roba koja se uplaćuje virmanski iskucava se po zvaničnim izvodima i isporuka se vrši po planu prevoza, nakon izdate fakture.<br /><br />
9.	Za neblagovremeno plaćanje, obračunavamo zateznu kamatu. Molimo da se pri plaćanju pozovete na broj računa.
      U slučaju spora nadležan je Privredni sud u Podgorici.<br /><br />

      
      
      
      <!--
      
        1. Salon preuzima obavezu besplatnog prevoza (do mjesta dostupnog
        teretnim vozilima) i montaže robe, ali <strong>NE I UNOŠENJE</strong> u
        stan kupca i <strong>NE KAČENJE</strong> elemenata na zid. Za
        neuklapanje artikala u stambeni prostor potrošača salon ne snosi
        odgovornost. <br /><br />
        2. Isporučena , otpakovana ili namontirana roba , kao i roba prodata
        (proknjižena) u predhodnom mjesecu ne može se zamijeniti.
        <br /><br />
        3. Prodavac se obavezuje da prema uslovima iz ovog ugovora poruči i
        proda artikal kupcu, u svemu po specifikaciji koja je sastavni dio
        ugovora.
        <br /><br />
        4. Za poručenu robu kupac se obavezuje da uplati avans minimum u iznosu
        od 50% vrijednosti naručene robe na dan potpisivanja ugovora , nakon
        čega počinju teći rokovi iz člana 4. Ostalih 50% plaća po prispijeću
        robe u magacin.
        <br /><br />
        5. Rok za prispijeće robe u centralni magacin prodavca iznosi do dana, s
        tim što se u slučaju nepredvidjenih okolnosti smetnji u prevozu,
        vremenskih nepogoda, zadržavanju na carinskom terminalu,ovaj rok može
        produziti za još 7 dana.
        <br /><br />
        6. U slučaju prekoračenja rokova isporuke iz člana 4. ugovora, kupac
        zadržava pravo na povraćaj avansa sa zakonskom zateznom kamatom, ili
        dodatni popust od 10%, ili se nudi zamjena za drugi artikal uz
        odgovarajući popust od strane "Namos Dajković Company"
        <br /><br />
        7. Račun je garancija na kupljenu robu u trajanju od 24 mjecesa.
        <br /><br />
        
        
        -->
        
        <strong>NAPOMENA</strong>
        
        Poštovani kupci, zbog iskrene i velike želje da budete ispoštovani,firma “Dajković Co Namos’’ je obezbijedila video nadzor, kako salona tako i svih vozila, robe u transportu kao i samu primopredaju robe. Svi zaposleni sa kojima komunicirate imaju ID kartice. U slučaju bilo kakve sporne situacije, molimo Vas da kontaktirate naše službe. Ako niste zadovoljni odgovorom, na raspolaganju Vam je e-mail adresa generalnog direktora i vlasnika: dajkovic.company@namos.co.me.
        
        <br /><br />
        
        <!--
        Poštovani kupci, zbog iskrene i velike želje da budete ispoštovani u
        firmi Namos je obezbijedio video nadzor kako salona tako i svih vozila,
        robe u transportu kao i samu primopredaju robe. Svi zaposleni sa kojima
        komunicirate imaju ID karticu u slučaju bilo kakve situacije, molimo Vas
        da kontaktirate naše službe. Ako nijeste zadovoljni odgovorom na
        raspolaganju Vam je kontekt generalnog direktora i vlasnika,
        namos@t-com.me <br /><br />
        srdačan pozdrav,<br /><br />
        -->
        
        <strong>Vaš svijet namještaja "Dajković Co Namos"</strong>
      </p>
    </div>
    <div id="faqWrapper">
      <div>
        <h2><a name="cesta_pitanja"></a>FAQ - Česta pitanja</h2>
        <p>
          <b> - Pitanje:</b><br />
          Da li namještaj može da se plati po isporuci?<br />
          <b> - Odgovor:</b><br />
          Namještaj nije moguće platiti po isporuci. Po pravilniku firme mora
          biti uplaćen kompletan iznos sa predračuna da bi se obavila isporuka.
          <br /><br />

          <b> - Pitanje:</b><br />
          Da li Namos ima prodajnih mjesta u nekom drugom gradu mimo
          Podgorice?<br />
          <b> - Odgovor:</b><br />
          Nema, naš specijalizovani salon namještaja na 4200m2 se nalazi u
          Podgorici (Cetinjski put bb - Donja Gorica). Ukoliko niste u
          mogućnosti da nas posjetite, moguće je telefonom poručiti namještaj
          koji možete pogledati na sajtu ili u flajerima i katalozima koji često
          izlaze uz dnevne novine Vijesti i Dan. Naravno prevoz i montaža su
          besplatni.<br /><br />

          <b> - Pitanje:</b><br />
          Da li Namos vrši isporuku namještaja na teritoriji Srbije?<br />
          <b> - Odgovor:</b><br />
          Za vrijeme trajanja "Beogradskog sajma namještaja" koji se održava
          svake godine početkom novembra isporučujemo robu i na teritoriji
          Srbije.<br /><br />
          <br /><br />
        </p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  mounted() {
    window.scrollTo(0, 0);
  },
};
</script>
<style lang="scss">



// MOBILE =================================================================================================
@media only screen and (max-width: 600px) {

#servisWrapper {
  position: relative;
  width: 100vw;
  padding: 5vw;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-sizing: border-box;
}

#faqWrapper {
  width: 100%;
}

#tableWrapper {
  width: 100%;
}

#isporuka {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
  font-size: 3vw;
  position: relative;
}

#isporuka tr:first-child {
  width: 10vw;
}

#isporuka td,
#isporuka th {
  border: 1px solid #ddd;
  padding:2vw;
}

#isporuka tr:nth-child(even) {
  background-color: #f2f2f2;
}

#isporuka tr:hover {
  background-color: #ddd;
}

#isporuka th {
  padding-top: 2vw;
  padding-bottom: 2vw;
  text-align: center;
  background-color: #62a846;
  color: white;
  position: sticky;
  top: 0px;
}
#isporuka td {
  padding-top: 1vw;
  padding-bottom: 1vw;
  text-align: left;
}

#isporuka img {
  height: 4vw;
}

#pravilnikWrapper {
  width: 100%;
}
}







// DESKTOP =================================================================================================

@media only screen and (min-width: 601px) {

#servisWrapper {
  position: relative;
  width: 100vw;
  padding: 3vw;
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
}

#faqWrapper {
  width: 20vw;
}

#tableWrapper {
  width: 30vw;
}

#isporuka {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
  font-size: 1vw;
  position: relative;
}

#isporuka tr:first-child {
  width: 10vw;
}

#isporuka td,
#isporuka th {
  border: 1px solid #ddd;
  padding: 8px;
}

#isporuka tr:nth-child(even) {
  background-color: #f2f2f2;
}

#isporuka tr:hover {
  background-color: #ddd;
}

#isporuka th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: center;
  background-color: #62a846;
  color: white;
  position: sticky;
  top: 0px;
}
#isporuka td {
  padding-top: 0.4vw;
  padding-bottom: 0.4vw;
  text-align: left;
}

#isporuka img {
  height: 1vw;
}

#pravilnikWrapper {
  width: 34vw;
}
}
</style>

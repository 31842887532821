<template>
  <div id="namjestajWrapp">
      <router-view :key="$route.path"></router-view>
  </div>
</template>
<script>
export default {
  data() {
    return {

    };
  },
};
</script>
<style lang="scss" scoped>

</style>

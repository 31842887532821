<template>
  <div id="oNamaWrapper">
    <div id="slideWrapper">
      <vueper-slides
        fade
        :visible-slides="isMobile ? 1 : 3"
        :slide-ratio="isMobile ? 1 / 1.7 : 1 / 5"
        :arrows-outside="false"
        autoplay
        :bullets="false"
        :slideMultiple="false"
        :duration="2000"
        :pauseOnHover="false"
        
      >
        <vueper-slide v-for="i in 12" :key="i" :image='"/images/onama/"+i+".jpg"' />
      </vueper-slides>
      

      
      
    </div>
    
    
    <div id="naslovWrap">
      <div id="naslov"><h1>O nama</h1></div>
    </div>
    <div id="textWrapper">
      Privatno preduzeće NAMOS je osnovano 1994 godine, sa maloprodajnim salonom
      namještaja u naselju Momišići u Podgorici.<br /><br />
      1997 - Firma NAMOS postaje distributer slovenačke privrede, gdje uspješno
      kao eksluzivni zastupnik slovenačkih fabrika namještaja, pored maloprodaje
      otvara i veleprodaju namještaja u Crnoj Gori sa regionalnom distribucijom
      i prodajom namještaja u Srbiji, Bosni i Hercegovini, Makedoniji.<br /><br />
      2001 - Osnovana je firma &quot; D.o.o. Dajković co Namos &quot; čiji je
      osnivač Vladan Dajković. Nakon 2001 godine, Namos uspijeva da proširi
      svoju mrežu nabavke na evropske zemlje (Italija, Njemačka, Francuska,
      Poljska, Ukrajina ).<br /><br />
      2006 - Otvara tržni centar u Podgorici - Donja Gorica bb, na 4200m2 i
      razvija distributivni centar na 9000m2 magacinskog prostora, odakle se
      vrši distribucija namještaja za kompletan region.<br /><br />
      <br /><br />
      Naša firma trenutno broji preko 100 zaposlenih, visoko profesionalno
      obučenih ljudi.<br /><br />
      Crnogorski zvanično NO.1 brend u prodaji namještaja Namos Dajković
      company, bvec četvrtu godinu zaredom niže nagrade kako od međunarodne
      institucije iz Švajcarske tako i od domacih institucija.<br />
      -
      <strong
        >Solvent Rating Publikacija i sertifikat za 4 godine izvrsnog
        poslovanja</strong
      ><br />Namos je na listi poslovnih subjekata koji imaju pravo na
      publikaciju i sertifikat za 4 godine poslovne izvrsnosti, i koji u
      kontinutitetu od 2016. godine ispunjavaju sve kriterijume likvidnosti I
      solventnosti, što predstavlja najviši stepen uspješnosti poslovanja.<br /><br />
      - <strong>Excellent - PKCG</strong> - po izboru Privredne komore Crne Gore
      i bonitetne kuće Coface, preduzeće je svrstano u grupu uspješnih i
      pouzdanih preduzeća sa izuzetnim bonitetnim rezultatima.<br />
      - <strong>100 najvecih u Crnoj Gori</strong> za 2019 godinu<br />
      - <strong>Qudal MEDAL</strong> - zlatna medalja ( No.1 na osnovu glasova
      kupaca iz Crne Gore )<br />
      - <strong>Best buy Award</strong> - najbolji odnos cijene i kvaliteta<br />
    </div>
    <br><br><br><br>
  </div>
</template>
<script>
import { VueperSlides, VueperSlide } from 'vueperslides'
import 'vueperslides/dist/vueperslides.css'

export default {
  components: { VueperSlides, VueperSlide },
  data() {
    return {
      a: "/images/slider/1.gif",
      cntr: 2,
      //slides: ["/images/onama/1.jpg", "/images/onama/2.jpg", "/images/onama/3.jpg", "/images/onama/4.jpg", "/images/onama/5.jpg", "/images/onama/6.jpg"],
    };
  },
  mounted() {
	
    setInterval(() => {
      this.a = "/images/slider/" + this.cntr + ".gif";
      this.cntr++;
      if (this.cntr == 4) this.cntr = 1;
    }, 5000);
  
    window.scrollTo(0, 0)
    
  },
  computed: {
    isMobile: function() {
      return this.$store.getters.isMobile;
    }
  }    
};
</script>
<style lang="scss">




// MOBILE =================================================================================================
@media only screen and (max-width: 600px) {


#oNamaWrapper {
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 4vw;
}

#slideWrapper {
  position: relative;
  height: 60vw;
  width: 100vw;
  margin-bottom: 2vw;
}

.hooper {
  height: auto;
}

.hooper img {
  width: 100%;
}

#naslovWrap {
  width: 100vw;
  display: flex;
  flex-direction: row;
  text-transform: uppercase;
  align-items: center;
  justify-content: center;

  h1,
  h2 {
    margin: 5vw;
    padding: 0px;
    text-indent: 0px;
  }

  h1 {
    color: rgb(95, 95, 95);
    font-weight: 300;
    font-size: 6vw;
  }
  h2 {
    color: rgb(83, 83, 83);
    font-weight: 300;
    font-variant: small-caps;
    text-transform: none;
  }
}
#textWrapper {
  width: 90vw;
  margin-bottom: 4vw;
}
}







// DESKTOP =================================================================================================


@media only screen and (min-width: 601px) {


#oNamaWrapper {
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 1vw;
  padding: 0 !important;
}

#slideWrapper {
  position: relative;
  height: 21vw;
  width: 100vw;
  margin-bottom: 5vw;
}

.hooper {
  height: auto;
}

.hooper img {
  width: 100%;
}

#naslovWrap {
  width: 100vw;
  display: flex;
  flex-direction: row;
  text-transform: uppercase;
  align-items: center;
  justify-content: center;

  h1,
  h2 {
    margin: 1vw;
    padding: 0px;
    text-indent: 0px;
  }

  h1 {
    color: rgb(95, 95, 95);
    font-weight: 300;
    font-size: 2vw;
  }
  h2 {
    color: rgb(83, 83, 83);
    font-weight: 300;
    font-variant: small-caps;
    text-transform: none;
  }
}
#textWrapper {
  width: 50vw;
  margin-bottom: 4vw;
}
}
</style>

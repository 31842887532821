<template>
<div>

  <div id="kataloziWrap">
    <div id="naslovWrap">
      <div id="naslov"><h1>Kataloška ponuda</h1></div>
      <div id="podNaslov"><h2>za informacije o narudžbi pozovite na <strong>067/666-150</strong></h2></div>
    </div>
    <a v-for="(item_a, i) in katList" :key="i" :href="item_a.link" target="_blank"><div class="katalogWrap">
      <img :src="'/images/katalozi/'+item_a.cover"/>
      <div class="opis">{{item_a.naziv}}</div>
    </div> </a>
  </div>
  
  
<div id="partneriWrapper">
    <div id="naslovWrap">
      <div id="naslov"><h1>Generalni zastupnik namještaja za Crnu Goru - Namos Dajkovic Company.</h1></div>
    </div>
    <div v-for="(item, index) in dobList" :key="index">
    
    <a v-if="item.dob_link && item.dob_link.length > 4" :href="item.dob_link" target="_blank">
      <div class="dobavljacWrapper">
        <img :src='"/images/dobavljaci/"+item.dob_url+".png"' />
        <div class="naziv">{{item.dob_naziv}}</div>
        <div class="lokacija">{{item.dob_lokacija}}</div>
        <div class="postest">Posjetite web sajt</div>
      </div>
    </a>  
    
    <a v-else target="_blank">
      <div class="dobavljacWrapper">
        <img :src='"/images/dobavljaci/"+item.dob_url+".png"' />
        <div class="naziv">{{item.dob_naziv}}</div>
        <div class="lokacija">{{item.dob_lokacija}}</div>
      </div>
    </a>  
        
    </div>
  </div>
  
  
  
 </div> 
</template>
<script>
export default {
  data() {
    return {
      katList:[],
      dobList: [],
    };
  },
    mounted() {
    window.scrollTo(0, 0);
    this.loadItems();
  },
  methods:{
    async loadItems() {
      const response = await fetch("/kats.php");
      const data = await response.json();
      this.katList = data;
      
      
      const response1 = await fetch("/dobs.php");
      const data1 = await response1.json();
      this.dobList = data1;
      
    },
  }
};
</script>
<style lang="scss" scoped>



// MOBILE =================================================================================================
@media only screen and (max-width: 600px) {
	
	
#partneriWrapper {
  position: relative;
  width: 100vw;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  box-sizing: border-box;
  padding: 5vw 6vw 4vw;
  justify-content: space-between;
}

#naslovWrap {
  width: 100vw;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  h1,
  h2 {
    margin: 1vw;
    padding: 0px;
    text-indent: 0px;
  }

  h1 {
    color: rgb(95, 95, 95);
    font-weight: 300;
    font-size: 4vw;
  }
  h2 {
    color: rgb(83, 83, 83);
    font-weight: 300;
    font-variant: small-caps;
    text-transform: none;
  }
}

.dobavljacWrapper {
  position: relative;
  box-sizing: border-box;
  width: 40vw;
  padding: 1.6vw 0vw;
  text-align: center;
  overflow: hidden;
  background-color: #ffffff;
  cursor: pointer;
  border-radius: 0vw;
  margin: 5vw 0px;

  img {
    width: 100%;
  }
  .postest{
    opacity: 1;
    transition: opacity 0.4s;
    position: relative;
    width: 100%;
    bottom: 0.5vw;
    font-size: 3vw;
    color: rgb(125, 185, 135);    
    margin-top: 2vw;
  }  
}


.naziv {
  font-weight: 800;
  font-size: 1.1vw;
  text-transform: uppercase;
}

.sajt {
  display: none;
}

.dobavljacWrapper:hover .sajt {
  display: block;
}
	
	
	

#kataloziWrap {
  position: relative;
  width: 100vw;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  box-sizing: border-box;
  padding: 5vw 5vw 10vw;
}

#naslovWrap{
    width: 100vw;
    display: flex;
    flex-direction: column;
    text-transform: uppercase;
    align-items: center;
    justify-content: center;
  text-align: center;
    h1, h2{
        margin: 2vw;
        padding: 0px;
        text-indent: 0px;
    }

    h1{
        color: rgb(95, 95, 95);
        font-weight: 900;
        font-size: 5vw;
    }
    h2{
        color: rgb(83, 83, 83);        
        font-weight: 300;
        font-variant: small-caps;
        text-transform: none;
        font-size: 6vw;
    }
}

.katalogWrap {
  box-sizing: border-box;
  width: 40vw;
  padding: 2vw;
  transition: transform 0.2s, background-color 0.2s;
  transition-timing-function: ease-in-out;
  background-color: #fff;
  margin: 2vw;
  img {
    width: 100%;
  }
}

.opis{
  font-weight: 600;
  font-size: 4vw;
  text-align: left;
}
}














// DESKTOP =================================================================================================


@media only screen and (min-width: 601px) {
	

#partneriWrapper {
  position: relative;
  width: 100vw;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  box-sizing: border-box;
  padding: 1.8vw 6vw 4vw;
}

#naslovWrap {
  width: 100vw;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  h1,
  h2 {
    margin: 1vw;
    padding: 0px;
    text-indent: 0px;
  }

  h1 {
    color: rgb(95, 95, 95);
    font-weight: 300;
    font-size: 2vw;
  }
  h2 {
    color: rgb(83, 83, 83);
    font-weight: 300;
    font-variant: small-caps;
    text-transform: none;
  }
}

.dobavljacWrapper {
  position: relative;
  box-sizing: border-box;
  width: 10vw;
  margin: 1.2vw;
  padding: 1.6vw 0vw;
  background-color: #f8f8f8;
  border-radius: 1vw;
  text-align: center;
  overflow: hidden;
  transition: transform 0.2s, border-radius 0.3s, background-color 0.2s;
  transition-timing-function: ease-in-out;
  img {
    width: 100%;
  }
  .postest{
    opacity: 0;
    transition: opacity 0.4s;
    position: absolute;
    width: 100%;
    bottom: 0.5vw;
    font-size: 0.6vw;
    color: rgb(125, 185, 135);    
  }  
}

.dobavljacWrapper:hover {
  background-color: #ffffff;
  cursor: pointer;
  transform: scale(1.2);
  border-radius: 0vw;
  transition: transform 0.3s, border-radius 1s, background-color 0.3s;
  transition-timing-function: ease-in-out;
  .postest{
    opacity: 1;    
  }  
}

.naziv {
  font-weight: 800;
  font-size: 1.1vw;
  text-transform: uppercase;
}

.sajt {
  display: none;
}

.dobavljacWrapper:hover .sajt {
  display: block;
}	
	

#kataloziWrap {
  position: relative;
  width: 100vw;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  box-sizing: border-box;
  padding: 1.5vw 1.9vw 4vw;
}

#naslovWrap{
    width: 100vw;
    display: flex;
    flex-direction: row;
    text-transform: uppercase;
    align-items: center;
    justify-content: center;

    h1, h2{
        margin: 1vw;
        padding: 0px;
        text-indent: 0px;
    }

    h1{
        color: rgb(95, 95, 95);
        font-weight: 300;
        font-size: 2vw;
    }
    h2{
        color: rgb(83, 83, 83);        
        font-weight: 300;
        font-variant: small-caps;
        text-transform: none;
    }
}

.katalogWrap {
  box-sizing: border-box;
  width: 12vw;
  padding: 1vw;
  transition: transform 0.2s, background-color 0.2s;
  transition-timing-function: ease-in-out;
  img {
    width: 100%;
  }
}

.katalogWrap:hover {
  background-color: #ffffff;
  cursor: pointer;
  transform: scale(1.3);
  transition: transform 0.3s, background-color 0.3s;
  transition-timing-function: ease-out;
}

.opis{
  font-weight: 600;
  font-size: 0.9vw;
  text-align: left;
}
}
</style>

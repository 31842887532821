<template>
  <div id="aktuelniWrapper">
    <img class="page" v-for="(strana, index) in lista" :key="index" :src="'/katalog/'+strana">
  </div>
</template>
<script>
export default {
  data() {
    return {
      lista: [],
      vrijeme: ''
    }
  },
    mounted() {
    window.scrollTo(0, 0)
    
  },
  created(){
    this.loadItems();
  },
  methods: {
    async loadItems() {
      const response = await fetch("/akat.php");
      const data = await response.json();
      this.lista = data;
    },
  }  
};
</script>
<style lang='scss'>

#aktuelniWrapper {
    position: relative;
    width: 100vw;
    padding: 50px 2vw;
    box-sizing: border-box;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.page{
    width: 820px;
    max-width: 90vw;
}



</style>
